import { createContext, useState, useContext } from "react";
import Backdrop from "@mui/material/Backdrop";

// Create the LoadingContext with an initial undefined value
export const LoadingContext = createContext(undefined);

// Define the LoadingProvider component
export const LoadingProvider = ({ children }) => {
  // State to keep track of the loading status
  const [isLoading, setIsLoading] = useState(false);

  // The provider will pass down isLoading and setIsLoading to its children
  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        setIsLoading,
      }}
    >
      {/* Render the Backdrop when isLoading is true */}
      <Backdrop
        open={isLoading}
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          img: {
            width: "90px",
            height: "90px",
          },
        }}
      >
        <img alt="Loading" src="/spinner.svg" />
      </Backdrop>
      {children}
    </LoadingContext.Provider>
  );
};

// Custom hook to access the loading context
export function useLoading() {
  const context = useContext(LoadingContext);
  if (context === undefined) {
    throw new Error("useLoading must be used within a LoadingProvider");
  }
  return context;
}
