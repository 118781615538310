import React, { useEffect, useState } from "react";
import { Box, Button, Input, Link, Stack, Typography } from "@mui/material";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import ScrollAnimation from "react-animate-on-scroll";
import Countdown from "react-countdown";
import { toast } from "react-hot-toast";

import { useWallet } from "@solana/wallet-adapter-react";

import {
  END_TIME,
  NETWORK_URL,
  PRESALE_ADDRESS,
  PRICE_PER_TOKEN,
  START_TIME,
} from "../utils";
import {
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  TransactionMessage,
  VersionedTransaction,
} from "@solana/web3.js";
import { useLoading } from "../contexts/loading-context";

const countdownRenderer = ({ days, formatted }) => {
  if (days > 0) {
    return <Typography>{days} Days</Typography>;
  } else {
    return (
      <Typography>
        {formatted.hours}:{formatted.minutes}:{formatted.seconds}
      </Typography>
    );
  }
};

export default function Banner() {
  const { select, wallets, publicKey, disconnect } = useWallet();
  const { setIsLoading } = useLoading();

  const [realStartTime, setRealStartTime] = useState(0);
  const [realEndTime, setRealEndTime] = useState(0);
  const [presaleState, setPresaleState] = useState(0);
  const [quoteAmount, setQuoteAmount] = useState(undefined);

  const handleWalletConnect = () => {
    if (!publicKey) {
      const installedWallets = wallets.filter(
        (wallet) => wallet.readyState === "Installed"
      );
      if (installedWallets.length <= 0) {
        toast.error("Phantom wallet is not installed yet.");
        return;
      }
      select(wallets[0].adapter.name);
    } else {
      disconnect();
    }
  };

  const handleBuyToken = async () => {
    console.log("handleBuyToken log - 1 : ", quoteAmount);
    setIsLoading(true);
    try {
      const web3Conn = new Connection(NETWORK_URL, "confirmed");
      const txInstructions = [];

      const transferInst = SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: new PublicKey(PRESALE_ADDRESS),
        lamports: Math.floor(quoteAmount * LAMPORTS_PER_SOL),
      });
      txInstructions.push(transferInst);

      const messageV0 = new TransactionMessage({
        payerKey: new PublicKey(publicKey),
        recentBlockhash: (await web3Conn.getLatestBlockhash()).blockhash,
        instructions: txInstructions,
      }).compileToV0Message();

      const transaction = new VersionedTransaction(messageV0);

      const { solana } = window;
      let signed = await solana.signAndSendTransaction(transaction);
      let signature = await web3Conn.confirmTransaction(signed.signature);
      console.log("Signature is ", signature);
      toast.success("Buy token successful");
    } catch (error) {
      toast.error("Buy token failed");
    }
    setIsLoading(false);
  };

  // const getTransactions = async () => {
  //   const startTime = new Date(START_TIME).getTime();
  //   const endTime = new Date(END_TIME).getTime();
  //   if (!publicKey) return;

  //   try {
  //     console.log("getTransactions log - 1 : ", NETWORK_URL);
  //     const web3Conn = new Connection(NETWORK_URL, "confirmed");

  //     // Fetch the signature of all transactions involving the publicKey
  //     const signatures = await web3Conn.getConfirmedSignaturesForAddress2(
  //       new PublicKey(publicKey),
  //       {
  //         before: Math.floor(startTime / 1000).toString(),
  //         until: Math.floor(endTime).toString(),
  //       }
  //     );

  //     // Filter transactions by date and recipient
  //     const filteredTransactions = [];
  //     for (let signatureInfo of signatures) {
  //       const transactionDetails = await web3Conn.getParsedConfirmedTransaction(
  //         signatureInfo.signature
  //       );

  //       if (transactionDetails) {
  //         const transactionUnixTime = transactionDetails.blockTime * 1000; // Convert to milliseconds

  //         // Check if the transaction falls within the desired time range
  //         if (
  //           transactionUnixTime >= startTime &&
  //           transactionUnixTime <= endTime
  //         ) {
  //           // Check if the transaction was sent to the PRESALE_ADDRESS
  //           const transactionInstructions =
  //             transactionDetails.transaction.message.instructions;
  //           for (let instruction of transactionInstructions) {
  //             if (
  //               instruction.parsed &&
  //               instruction.parsed.type === "transfer" &&
  //               instruction.parsed.info.destination === PRESALE_ADDRESS
  //             ) {
  //               filteredTransactions.push({
  //                 signature: signatureInfo.signature,
  //                 blockTime: transactionUnixTime,
  //                 amount: instruction.parsed.info.lamports,
  //               });
  //             }
  //           }
  //         }
  //       }
  //     }

  //     console.log("Filtered Transactions: ", filteredTransactions);
  //     return filteredTransactions;
  //   } catch (error) {
  //     console.error("Error fetching transactions: ", error);
  //   }
  // };

  // useEffect(() => {
  //   if (publicKey) {
  //     getTransactions();
  //   }
  // }, [publicKey]);

  useEffect(() => {
    const startTime = new Date(START_TIME);
    const endTime = new Date(END_TIME);
    if (startTime && endTime) {
      setRealStartTime(startTime);
      setRealEndTime(endTime);
      const currentTime = Date.now();
      if (currentTime < startTime) {
        setPresaleState(1);
      } else if (currentTime > endTime) {
        setPresaleState(3);
      } else {
        setPresaleState(2);
      }
    }
  }, []);

  return (
    <Box>
      <Stack
        direction="column"
        alignItems="center"
        gap={{ xs: "30px", sm: "50px" }}
        sx={{
          paddingBottom: "60px",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            width: "100%",
            maxWidth: "1420px",
            paddingX: "10px",
            paddingY: "20px",
          }}
        >
          <Box
            sx={{
              img: {
                height: { xs: "40px", sm: "60px" },
              },
            }}
          >
            <img alt="Logo" src="./dogwifteef-logo.png" />
          </Box>
        </Stack>
        <Stack
          direction="column"
          alignItems="center"
          gap="40px"
          sx={{
            width: "100%",
            maxWidth: "900px",
            paddingX: "20px",
          }}
        >
          <ScrollAnimation animateIn="bounceInRight" initiallyVisible>
            <Stack direction="column" gap="10px">
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: { xs: "32px", sm: "54px" },
                  fontWeight: 400,
                  color: "#000000",
                }}
              >
                dogwifteef presale🚀
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  fontFamily: "Montserrat !important",
                  fontSize: { xs: "14px", sm: "18px" },
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                what is dogwifteef? just a dog wif teef.
              </Typography>
            </Stack>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="zoomIn"
            initiallyVisible
            style={{
              width: "100%",
            }}
          >
            <Stack
              direction="column"
              sx={{
                width: "100%",
                border: "1px solid #ebebeb",
                borderRadius: "22px",
                background: "#ffffff",
                overflow: "hidden",
              }}
            >
              <Stack
                direction="column"
                alignItems="center"
                gap="15px"
                sx={{
                  padding: "20px",
                }}
              >
                <Stack direction="column" alignItems="center" gap="10px">
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: 400,
                      color: "#000000",
                      textAlign: "center",
                    }}
                  >
                    {presaleState === 1
                      ? "PRESALE WILL START IN"
                      : presaleState === 2
                      ? "PRESALE WILL END IN"
                      : presaleState === 3
                      ? "PRESALE ENDED"
                      : "LOADING PRESALE INFO..."}
                  </Typography>
                  {(presaleState === 1 || presaleState === 2) && (
                    <Countdown
                      date={presaleState === 1 ? realStartTime : realEndTime}
                      renderer={countdownRenderer}
                    />
                  )}
                </Stack>
              </Stack>
              <Stack
                direction="column"
                gap="10px"
                sx={{
                  border: "1px solid #ebebeb",
                  borderRadius: "22px",
                  background: "#fbfbfb",
                  padding: "20px",
                }}
              >
                <Stack direction="row" alignItems="center" gap="10px">
                  <Box
                    sx={{
                      width: "100%",
                      height: "1px",
                      background: "#ebebeb",
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Montserrat !important",
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#000000",
                      whiteSpace: "nowrap",
                    }}
                  >
                    1 SOL = 100,000 $teef
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      height: "1px",
                      background: "#ebebeb",
                    }}
                  />
                </Stack>
                <Stack direction="row" alignItems="center" gap="20px">
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap="10px"
                    sx={{
                      height: "60px",
                      paddingX: "20px",
                      border: "1px solid #ebebeb",
                      borderRadius: "22px",
                      background: "#ffffff",
                      img: {
                        width: "44px",
                        height: "44px",
                        borderRadius: "22px",
                      },
                    }}
                  >
                    <img alt="SOL" src="/sol.png" />
                    <Typography
                      sx={{
                        fontFamily: "Montserrat !important",
                        fontSize: "16px",
                        fontWeight: 400,
                        color: "#000000",
                      }}
                    >
                      SOL
                    </Typography>
                  </Stack>
                  <Input
                    value={quoteAmount}
                    onChange={(e) => {
                      setQuoteAmount(e.target.value);
                    }}
                    type="number"
                    placeholder="SOL you pay"
                    sx={{
                      width: "100%",
                      height: "60px",
                      paddingX: "20px",
                      border: "1px solid #ebebeb",
                      borderRadius: "22px",
                      ".MuiInputBase-input": {
                        fontFamily: "Montserrat !important",
                        fontSize: "16px",
                        fontWeight: 400,
                        color: "#000000",
                      },
                      ":before": {
                        display: "none",
                      },
                      ":after": {
                        display: "none",
                      },
                    }}
                  />
                </Stack>
                {/* <Stack direction="column" alignItems="center">
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontFamily: "Montserrat !important",
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#000000",
                    }}
                  >
                    YOU WILL GET
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontFamily: "Montserrat !important",
                      fontSize: "24px",
                      fontWeight: 600,
                      color: "#000000",
                    }}
                  >
                    {(quoteAmount
                      ? quoteAmount / PRICE_PER_TOKEN
                      : 0
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    })}{" "}
                    $teef
                  </Typography>
                </Stack> */}
                <Stack
                  direction="row"
                  alignItems="center"
                  gap="5px"
                  sx={{
                    ".MuiButton-root": {
                      width: "100%",
                      height: "60px",
                      borderRadius: "22px",
                      background: "#e3544e",
                      fontFamily: "Montserrat !important",
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#000000",
                      ":hover": {
                        background: "#e3544e",
                      },
                    },
                  }}
                >
                  {!publicKey ? (
                    <Button onClick={handleWalletConnect}>
                      CONNECT WALLET
                    </Button>
                  ) : (
                    presaleState === 2 && (
                      <Button onClick={handleBuyToken}>BUY $teef</Button>
                    )
                  )}
                  {publicKey && presaleState === 2 && (
                    <Button
                      onClick={handleWalletConnect}
                      sx={{
                        width: "60px !important",
                      }}
                    >
                      <LinkOffIcon />
                    </Button>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </ScrollAnimation>
        </Stack>
      </Stack>
    </Box>
  );
}
