import React from "react";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import ScrollAnimation from "react-animate-on-scroll";
import { PRESALE_ADDRESS } from "../utils";
import toast from "react-hot-toast";
import XIcon from "@mui/icons-material/X";
import TelegramIcon from "@mui/icons-material/Telegram";

export default function Offering() {
  return (
    <Box>
      <Stack
        direction="column"
        alignItems="center"
        gap="50px"
        sx={{
          paddingY: "60px",
        }}
      >
	  <Stack
          direction="row"
          alignItems="center"
          gap="20px"
          sx={{
            ".MuiIconButton-root": {
              width: "70px",
              height: "70px",
              color: "#ffffff",
            },
          }}
        >
          <IconButton
            href="https://x.com/wifteef"
            target="_blank"
            sx={{
              background: "#000000",
              ":hover": {
                background: "#000000",
              },
            }}
          >
            <XIcon
              sx={{
                width: "30px",
                height: "30px",
              }}
            />
          </IconButton>
          <Box
            sx={{
              width: "3px",
              height: "40px",
              background: "#303030",
            }}
          />
          <IconButton
            href="https://t.me/teefann"
            target="_blank"
            sx={{
              background: "#2CA5E0",
              ":hover": {
                background: "#2CA5E0",
              },
            }}
          >
            <TelegramIcon
              sx={{
                width: "40px",
                height: "40px",
              }}
            />
          </IconButton>
        </Stack>
        <ScrollAnimation animateIn="bounceInLeft">
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            sx={{
              img: {
                height: "60px",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "40px",
                fontWeight: 400,
                color: "#000000",
              }}
            >
              how to buy $teef
            </Typography>
          </Stack>
        </ScrollAnimation>
        <Stack
          direction="column"
          gap="20px"
          sx={{
            width: "100%",
            maxWidth: "900px",
            paddingX: "20px",
          }}
        >
          <ScrollAnimation animateIn="fadeIn">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap="30px"
              sx={{
                padding: "20px",
                border: "1px solid #ebebeb",
                borderRadius: "24px",
                background: "#ffffff",
              }}
            >
              <Stack direction="column" gap="5px">
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 400,
                    color: "#000000",
                  }}
                >
                  click or tap on the presale address below
                </Typography>
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(PRESALE_ADDRESS);
                    toast.success("Address copied");
                  }}
                  sx={{
                    fontSize: "12px",
                    color: "#000000",
					wordBreak: 'break-all'
                  }}
                >
                  {PRESALE_ADDRESS}
                </Button>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "20px",
                  background: "#00000050",
                }}
              >
                <Typography
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "72px",
                  }}
                >
                  1
                </Typography>
              </Stack>
            </Stack>
          </ScrollAnimation>
          <ScrollAnimation animateIn="tada">
            <Stack
              direction="row"
              alignItems="center"
              gap="30px"
              sx={{
                padding: "20px",
                borderRadius: "24px",
                background: "#ffbc01",
                boxShadow: "0px 0px 10.4px 0px #00000040",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  width: "80px !important",
                  minWidth: "80px !important",
                  height: "80px",
                  borderRadius: "20px",
                  background: "#00000050",
                }}
              >
                <Typography
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "72px",
                  }}
                >
                  2
                </Typography>
              </Stack>
              <Stack direction="column" gap="5px">
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 400,
                    color: "#000000",
                  }}
                >
                  send sol (1 sol = 100,000 $teef)
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat !important",
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#000000",
                    //textTransform: "uppercase",
                  }}
                >
                  make sure you don't send from cex
                </Typography>
              </Stack>
            </Stack>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap="30px"
              sx={{
                padding: "20px",
                border: "1px solid #ebebeb",
                borderRadius: "24px",
                background: "#ffffff",
              }}
            >
              <Stack direction="column" gap="5px">
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 400,
                    color: "#000000",
                  }}
                >
                  tokens will be airdropped when presale ends
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat !important",
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#000000",
                    //textTransform: "uppercase",
                  }}
                >
                  keep an eye on your wallet for arrival of $teef
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  width: "80px !important",
                  minWidth: "80px !important",
                  height: "80px",
                  borderRadius: "20px",
                  background: "#00000050",
                }}
              >
                <Typography
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "72px",
                  }}
                >
                  3
                </Typography>
              </Stack>
            </Stack>
          </ScrollAnimation>
        </Stack>
      </Stack>
    </Box>
  );
}
