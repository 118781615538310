import { WalletConnectProvider } from "./components/wallet-connect-provider";
import { LoadingProvider } from "./contexts/loading-context";
import Banner from "./layouts/banner";
import Offering from "./layouts/offering";

export default function App() {
  return (
    <>
      <WalletConnectProvider>
        <LoadingProvider>
          <Banner />
          <Offering />
        </LoadingProvider>
      </WalletConnectProvider>
    </>
  );
}
